
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: left; 
    position: relative;

  }
  

  